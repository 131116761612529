import React from "react";
import ColorPicker from "./features/color-picker/color-picker";
import ColorPreview from "./features/color-preview/color-preview";
import styles from "./App.module.scss";
import { useSelector } from "react-redux";
import colorPickerRedux from "./features/color-picker/redux";
import { getColorString } from "./helper/color";
function App() {
  const color = useSelector(colorPickerRedux.selectors.selectColor);
  return (
    <div
      className={styles.container}
      style={{ background: getColorString(color, "rgba") }}
    >
      <ColorPicker />
    </div>
  );
}

export default App;
