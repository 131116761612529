import React, { useState } from "react";
import {
  // ChromePicker,
  AlphaPicker,
  SketchPicker,
  BlockPicker,
  CirclePicker,
  CompactPicker,
  HuePicker,
} from "react-color";
import ChromeField from "react-color/lib/components/chrome/ChromeFields";
import { Checkboard, Hue, Alpha } from "react-color/lib/components/common";
import { useDispatch, useSelector } from "react-redux";
import colorPickerRedux from "./redux";
import styles from "./color-picker.module.scss";
import { Saturation } from "react-color/lib/components/common";
import ChromePicker from "../chrome/Chrome";

import { useClipboard } from "use-clipboard-copy";

const { selectors, actions } = colorPickerRedux;

const ColorPicker = (props) => {
  const dispatch = useDispatch();
  const color = useSelector(selectors.selectColor);
  const onChangeColor = (color) => dispatch(actions.update(color));

  const clipboard = useClipboard();

  const pickerStyles = {};
  return (
    <div className={styles.container}>
      <ChromePicker
        width={"50vw"}
        styles={{
          default: {
            picker: {
              borderRadius: "8px",
              overflow: "hidden",
            },
            hue: { height: "18px", borderRadius: "100px", overflow: "hidden" },
            alpha: {
              height: "18px",
              borderRadius: "100px",
              overflow: "hidden",
            },
          },
        }}
        color={color.rgb}
        onChange={(color) => dispatch(actions.update(color))}
        copy={clipboard.copy}
      />
    </div>
  );
};

export default ColorPicker;
