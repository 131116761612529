import { createSlice } from "@reduxjs/toolkit";

const colorPicker = createSlice({
  name: "colorPicker",
  initialState: {
    hex: "#ffffff",
    rgb: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    hsl: {
      h: 250,
      s: 0,
      l: 1,
      a: 1,
    },
    hsv: {
      h: 250,
      s: 0,
      v: 1,
      a: 1,
    },
    oldHue: 250,
    source: "hsv",
  },
  reducers: {
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

const selectColor = (state) => state.colorPicker;

const colorPickerRedux = {
  ...colorPicker,
  selectors: {
    selectColor,
  },
};
export default colorPickerRedux;
