const getColorString = (color, type) => {
  switch (type) {
    case "rgba": {
      const { r, g, b, a } = color.rgb;
      return `rgba(${r},${g},${b},${a})`;
    }
    case "hsla": {
      const { h, s, l, a } = color.hsl;
      return `hsla(${h}, ${s}, ${l}, ${a})`;
    }
    default: {
      return color.hex;
    }
  }
};

export { getColorString };
